export const TOGGLE_FILTER = 'barmer-bonus-web/bonusshopstate/TOGGLE_FILTER' as const;
export const MONETARY_REWARD_SET_NAME = 'barmer-bonus-web/bonusshopstate/monetary_reward/SET_NAME' as const;
export const MONETARY_REWARD_SET_IBAN = 'barmer-bonus-web/bonusshopstate/monetary_reward/SET_IBAN' as const;
export const MONETARY_REWARD_VALIDATE = 'barmer-bonus-web/bonusshopstate/monetary_reward/VALIDATE' as const;
export const ADD_FILE = 'barmer-bonus-web/bonusshopstate/uploadfile/ADD_FILE' as const;
export const REMOVE_FILE = 'barmer-bonus-web/bonusshopstate/uploadfile/REMOVE_FILE' as const;
export const TOO_LARGE = 'barmer-bonus-web/bonusshopstate/uploadfile/TOO_LARGE' as const;
export const RESET = 'barmer-bonus-web/bonusshopstate/uploadfile/RESET' as const;
export const TYPE_ERROR = 'barmer-bonus-web/bonusshopstate/TYPE_ERROR' as const;
export const CLEAR_ERROR = 'barmer-bonus-web/bonusshopstate/CLEAR_ERROR' as const;
export const SET_CATEGORY = 'barmer-bonus-web/bonusshopstate/SET_CATEGORY' as const;

type ToggleFilter = { type: typeof TOGGLE_FILTER; payload: {} };
type MonetaryFilterName = { type: typeof MONETARY_REWARD_SET_NAME; payload: string };
type MonetaryFilterIban = { type: typeof MONETARY_REWARD_SET_IBAN; payload: string };
type MonetaryFilterValidate = { type: typeof MONETARY_REWARD_VALIDATE; payload: {} };
type AddFile = { type: typeof ADD_FILE; payload: { file: File } };
type AddBinary = { type: typeof ADD_FILE; payload: { file: File } };
type RemoveFile = { type: typeof REMOVE_FILE; payload: { index: number } };
type TooLarge = { type: typeof TOO_LARGE; payload: boolean };
type Reset = { type: typeof RESET; payload: {} };
type TypeError = { type: typeof TYPE_ERROR; payload: {} };
type ClearError = { type: typeof CLEAR_ERROR; payload: {} };
type SetCategory = { type: typeof SET_CATEGORY; payload: number };

export type Action =
  | ToggleFilter
  | MonetaryFilterIban
  | MonetaryFilterName
  | MonetaryFilterValidate
  | AddFile
  | AddBinary
  | RemoveFile
  | TooLarge
  | Reset
  | TypeError
  | ClearError
  | SetCategory;

export type State = {
  selectedCategory: number;
  filterBonusItems: boolean;
  iban: string;
  files: File[];
  fileTooLarge: boolean;
  typeError: boolean;
};

const initialState: State = {
  selectedCategory: 0, // 0 = all
  filterBonusItems: false,
  iban: '',
  files: [],
  fileTooLarge: false,
  typeError: false,
};

export default function reducer(state: State = initialState, action: Action): State {
  const newstate: State = { ...state };
  switch (action.type) {
    case MONETARY_REWARD_SET_IBAN:
      newstate.iban = action.payload;
      return newstate;
    case TOGGLE_FILTER:
      return {
        ...state,
        filterBonusItems: !state.filterBonusItems,
      };
    case ADD_FILE:
      return {
        ...state,
        fileTooLarge: false,
        files: [...state.files, action.payload.file],
      };
    case REMOVE_FILE:
      return {
        ...state,
        files: state.files.filter((_, i) => i !== action.payload.index),
      };
    case TOO_LARGE:
      return {
        ...state,
        fileTooLarge: action.payload,
      };
    case TYPE_ERROR:
      return {
        ...state,
        typeError: true,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        typeError: false,
        fileTooLarge: false,
      };
    case SET_CATEGORY:
      return {
        ...state,
        selectedCategory: action.payload,
      };
    case RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}

export function toggleBonusFilter(): ToggleFilter {
  return { type: TOGGLE_FILTER, payload: {} };
}

export function setMonetaryRewardIban(val: string): Action {
  return { type: MONETARY_REWARD_SET_IBAN, payload: val };
}

export function validateMonetaryReward(): Action {
  return { type: MONETARY_REWARD_VALIDATE, payload: {} };
}

export function addFile(file: File): AddFile {
  return { type: ADD_FILE, payload: { file } };
}

export function removeFile(index: number): Action {
  return { type: REMOVE_FILE, payload: { index } };
}

export function tooLarge(flag: boolean): Action {
  return { type: TOO_LARGE, payload: flag };
}

export function reset(): Action {
  return { type: RESET, payload: {} };
}

export function typeError(): Action {
  return { type: TYPE_ERROR, payload: {} };
}

export function clearError(): Action {
  return { type: CLEAR_ERROR, payload: {} };
}

export function setCategory(category: number): Action {
  return { type: SET_CATEGORY, payload: category };
}
