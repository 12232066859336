import { Action as AuthAction, LOGIN_SUCCESS } from 'barmer-bonus-shared/src/redux/modules/Auth';
import { Dispatch } from '../types/Store';

export default function skipOnboardingMiddleware() {
  // @ts-ignore
  return (next: Dispatch): Dispatch => (action: AuthAction) => {
    if (action.type === LOGIN_SUCCESS) {
      localStorage.setItem('hasLoggedIn', JSON.stringify(true));
    }
    next(action);
  };
}
