import { CSSObject } from '@emotion/react';

const breakpoints = {
  small: 320,
  medium: 640,
  large: 1000,
};

// TODO: it was added `any` because Conflicts with DS Theme.
const theme: any = {
  breakpoints,
  responsive: {
    xsmall: `(max-width: ${breakpoints.small - 1}px)`,
    small: `(min-width: ${breakpoints.small}px)`,
    medium: `(min-width: ${breakpoints.medium}px)`,
    large: `(min-width: ${breakpoints.large}px)`,
  },
  fonts: {
    body: 'Arial, sans-serif',
    heading: '"Titillium Web", sans-serif',
  },
  fontSizes: [14, 16, 18, 20, 22, 24, 26, 32],
  letterSpacings: [-0.34, -0.38, -0.43, -0.48, -0.52, -0.57, -0.62, -0.76],
  lineHeights: [1.4286, 1.5, 1.5556, 1.2, 1.1818, 1.25, 1.2308, 1.1875],
  space: [0, 4, 8, 16, 24, 32],
};

export const mixins = {
  fonts: {
    h1: (props: any): CSSObject => ({
      fontFamily: props.fonts.heading,
      fontSize: props.fontSizes[5],
      fontWeight: 700,
      letterSpacing: props.letterSpacings[5],
      lineHeight: props.lineHeights[5],

      [`@media ${props.responsive.large}`]: {
        fontSize: props.fontSizes[7],
        letterSpacing: props.letterSpacings[7],
        lineHeight: props.lineHeights[7],
      },
    }),
    h2: (props: any): CSSObject => ({
      fontFamily: props.fonts.heading,
      fontSize: props.fontSizes[3],
      fontWeight: 700,
      letterSpacing: props.letterSpacings[3],
      lineHeight: props.lineHeights[3],

      [`@media ${props.responsive.large}`]: {
        fontSize: props.fontSizes[6],
        letterSpacing: props.letterSpacings[6],
        lineHeight: props.lineHeights[6],
      },
    }),
    h3: (props: any): CSSObject => ({
      fontFamily: props.fonts.heading,
      fontSize: props.fontSizes[2],
      fontWeight: 700,
      letterSpacing: props.letterSpacings[2],
      lineHeight: props.lineHeights[2],

      [`@media ${props.responsive.large}`]: {
        fontSize: props.fontSizes[5],
        letterSpacing: props.letterSpacings[5],
        lineHeight: props.lineHeights[5],
      },
    }),
    h4: (props: any): CSSObject => ({
      fontFamily: props.fonts.heading,
      fontSize: props.fontSizes[1],
      fontWeight: 600,
      letterSpacing: props.letterSpacings[1],
      lineHeight: props.lineHeights[1],

      [`@media ${props.responsive.large}`]: {
        fontSize: props.fontSizes[4],
        letterSpacing: props.letterSpacings[4],
        lineHeight: props.lineHeights[4],
      },
    }),
    textRegular: (props: any): CSSObject => ({
      fontFamily: props.fonts.body,
      fontSize: props.fontSizes[1],
      letterSpacing: props.letterSpacings[1],
      lineHeight: 24 / 16,

      [`@media ${props.responsive.large}`]: {
        fontSize: props.fontSizes[2],
        letterSpacing: props.letterSpacings[2],
        lineHeight: 28 / 18,
      },
    }),
    textSmall: (props: any): CSSObject => ({
      fontFamily: props.fonts.body,
      fontSize: props.fontSizes[0],
      letterSpacing: props.letterSpacings[0],
      lineHeight: props.lineHeights[0],

      [`@media ${props.responsive.large}`]: {
        fontSize: props.fontSizes[1],
        letterSpacing: props.letterSpacings[1],
        lineHeight: props.lineHeights[1],
      },
    }),
  },
};

export default theme;
