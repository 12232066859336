export const ADD_FILE = 'barmer-bonus-web/plusuploadstate/ADD_FILE' as const;
export const SET_TITLE = 'barmer-bonus-web/plusuploadstate/SET_TITLE' as const;
export const REMOVE_FILE = 'barmer-bonus-web/plusuploadstate/REMOVE_FILE' as const;
export const TOO_LARGE = 'barmer-bonus-web/plusuploadstate/TOO_LARGE' as const;
export const RESET = 'barmer-bonus-web/plusuploadstate/RESET' as const;
export const TYPE_ERROR = 'barmer-bonus-web/plusuploadstate/TYPE_ERROR' as const;
export const CLEAR_ERROR = 'barmer-bonus-web/plusuploadstate/CLEAR_ERROR' as const;

type AddFile = { type: typeof ADD_FILE; payload: { file: File } };
type SetTitle = { type: typeof SET_TITLE; payload: string };
type SetBinary = { type: typeof ADD_FILE; payload: { file: File } };
type RemoveFile = { type: typeof REMOVE_FILE; payload: { index: number } };
type TooLarge = { type: typeof TOO_LARGE; payload: boolean };
type Reset = { type: typeof RESET; payload: {} };
type TypeError = { type: typeof TYPE_ERROR; payload: {} };
type ClearError = { type: typeof CLEAR_ERROR; payload: {} };

export type Action =
  | AddFile
  | SetBinary
  | RemoveFile
  | TooLarge
  | Reset
  | SetTitle
  | TypeError
  | ClearError;

export type State = {
  currentTitle: string;
  file: File | null;
  fileTooLarge: boolean;
  wrongFileType: boolean;
};

const initialState: State = {
  currentTitle: '',
  file: null,
  fileTooLarge: false,
  wrongFileType: false,
};

export default function reducer(state: State = initialState, action: Action): State {
  switch (action.type) {
    case ADD_FILE:
      return {
        ...state,
        fileTooLarge: false,
        file: action.payload.file,
      };
    case SET_TITLE:
      return {
        ...state,
        currentTitle: action.payload,
      };
    case REMOVE_FILE:
      return {
        ...state,
        file: null,
      };
    case TOO_LARGE:
      return {
        ...state,
        fileTooLarge: action.payload,
      };
    case TYPE_ERROR:
      return {
        ...state,
        wrongFileType: true,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        fileTooLarge: false,
        wrongFileType: false,
      };
    case RESET:
      return initialState;
    default:
      return state;
  }
}

export function addFile(file: File): Action {
  return { type: ADD_FILE, payload: { file } };
}

export function removeFile(index: number): Action {
  return { type: REMOVE_FILE, payload: { index } };
}

export function settitle(title: string): Action {
  return { type: SET_TITLE, payload: title };
}

export function tooLarge(flag: boolean): Action {
  return { type: TOO_LARGE, payload: flag };
}

export function reset(): Action {
  return { type: RESET, payload: {} };
}

export function typeError(): Action {
  return { type: TYPE_ERROR, payload: {} };
}

export function clearError(): Action {
  return { type: CLEAR_ERROR, payload: {} };
}
