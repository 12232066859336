import { ApiConfig } from 'barmer-bonus-shared/src/redux/middleware/apiConfigurationMiddleware';

const config: ApiConfig = {
  host: process.env.REACT_APP_API_URL ?? 'https://barmer-backend.acid-preview-dev.aperto.de',
  authorization: process.env.REACT_APP_API_KEY,
  cookieName: 'barmer',
};

export const urls = {
  appStoreLinks: {
    ios: 'https://apps.apple.com/de/app/barmer-app/id956752981',
    android: 'https://play.google.com/store/apps/details?id=de.barmergek.serviceapp',
  },
};

export const fileSizeLimits = {
  totalMaxBytes: 1024 * 1024 * 16.5,
  singleFileMaxBytes: 1024 * 1024 * 3.3,
};

export default config;
