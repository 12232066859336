import { Action } from '../types/Action';
import { State } from '../types/State';

type Reducer = (state: State, action: Action) => State;
type ResetCallback = (action: Action) => boolean;
export type ResetMatch = (ResetCallback | string)[];

export const DEFAULT_RESET_TYPE = 'barmer-bonus-shared/RESET_STATE' as const;

function shouldReset(current: Action, resetMatch: ResetMatch): boolean {
  for (let i = 0; i < resetMatch.length; i += 1) {
    if (Object.prototype.hasOwnProperty.call(resetMatch, i)) {
      const match = resetMatch[i];

      if (typeof match === 'function' && match(current)) {
        return true;
      }

      if (typeof match === 'string' && current.type === match) {
        return true;
      }
    }
  }
  return false;
}

export default function composeResetReducer(
  reducer: Reducer,
  initialState: State,
  resetActionTypes: ResetMatch,
): Reducer {
  return (state: State, action: Action): State => {
    let newState = state;
    if (shouldReset(action, resetActionTypes)) {
      newState = initialState;
    }

    return reducer(newState, action);
  };
}

export type ResetAction = { type: typeof DEFAULT_RESET_TYPE };

export function resetState(): ResetAction {
  return { type: DEFAULT_RESET_TYPE };
}
