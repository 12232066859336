import React from 'react';
import './index.css';

export default function App() {
  return (
    <div>
      <header>
        <div id="logo" />
      </header>
      <main>
        <h1>Wir sind umgezogen</h1>
        <p>
          Das Bonusprogramm finden Sie ab jetzt in Meine BARMER und natürlich auch in der
          BARMER-App. Loggen Sie sich dort ganz einfach mit Ihren bekannten Zugangsdaten für Ihr
          BARMER Benutzerkonto ein. Hier geht es zum Bonusprogramm in „Meine Barmer“
        </p>

        <div>
          <a href="https://www.barmer.de/bconnect/barmer-de/bonus/Bonus/dashboard">Meine BARMER</a>
        </div>
      </main>
    </div>
  );
}
