import { isKvnr } from 'barmer-bonus-shared/src/common/Validations';

export const SET_FIRST_NAME = 'barmer-bonus-web/bonuspointtransferstate/SET_FIRST_NAME' as const;
export const SET_LAST_NAME = 'barmer-bonus-web/bonuspointtransferstate/SET_LAST_NAME' as const;
export const SET_KVNR = 'barmer-bonus-web/bonuspointtransferstate/SET_KVNR' as const;
export const RESET = 'barmer-bonus-web/bonuspointtransferstate/RESET' as const;
export const VALIDATE = 'barmer-bonus-web/bonuspointtransferstate/VALIDATE' as const;
export const CLEAR_ERROR = 'barmer-bonus-web/bonuspointtransferstate/CLEAR_ERROR' as const;

type SetFirstName = { type: typeof SET_FIRST_NAME; payload: string };
type SetLastName = { type: typeof SET_LAST_NAME; payload: string };
type SetKvnr = { type: typeof SET_KVNR; payload: string };
type Reset = { type: typeof RESET; payload: {} };
type Validate = { type: typeof VALIDATE; payload: {} };
type ClearError = { type: typeof CLEAR_ERROR; payload: {} };

export type Action = SetFirstName | SetLastName | SetKvnr | Reset | Validate | ClearError;

export type State = {
  firstName: string;
  lastName: string;
  kvnr: string;
  validationError: boolean;
};

const initialState: State = {
  firstName: '',
  lastName: '',
  kvnr: '',
  validationError: false,
};

export default function reducer(state: State = initialState, action: Action): State {
  switch (action.type) {
    case SET_FIRST_NAME:
      return {
        ...state,
        firstName: action.payload,
      };
    case SET_LAST_NAME:
      return {
        ...state,
        lastName: action.payload,
      };
    case SET_KVNR:
      return {
        ...state,
        kvnr: action.payload,
      };
    case RESET:
      return initialState;
    case VALIDATE:
      return {
        ...state,
        validationError: !isKvnr(state.kvnr),
      };
    case CLEAR_ERROR:
      return {
        ...state,
        validationError: false,
      };
    default:
      return state;
  }
}

export function setFirstName(val: string): Action {
  return { type: SET_FIRST_NAME, payload: val };
}

export function setLastName(val: string): Action {
  return { type: SET_LAST_NAME, payload: val };
}

export function setKvnr(val: string): Action {
  return { type: SET_KVNR, payload: val };
}

export function reset(): Action {
  return { type: RESET, payload: {} };
}

export function validate(): Action {
  return { type: VALIDATE, payload: {} };
}

export function clearError(): Action {
  return { type: CLEAR_ERROR, payload: {} };
}
