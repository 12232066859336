import { cache } from '@emotion/css';
import { CacheProvider, ThemeProvider } from '@emotion/react';
import * as Sentry from '@sentry/react';
import { isErrorMonitoringEnabled } from 'barmer-bonus-shared/src/components/AnalyticsProvider';
import React from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import createStore from './createStore';
import './i18n';
import * as serviceWorker from './serviceWorker';
import theme from './theme';

Sentry.init({
  dsn: 'https://225a4647cfb74da09ebc28595fa5087b@sentry.live.aperto.de/2',
  environment: process.env.REACT_APP_ENV,
  release: `barmer-bonus-web@${process.env.REACT_APP_VERSION}`,
  beforeSend: (event) => (isErrorMonitoringEnabled(event.breadcrumbs) ? event : null),
});

const store = createStore();

const persistor = persistStore(store);

ReactDOM.render(
  <CacheProvider value={cache}>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </ThemeProvider>
  </CacheProvider>,
  document.querySelector('#root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
