import { Action as AuthAction, LOGOUT } from 'barmer-bonus-shared/src/redux/modules/Auth';
import {
  Action as DashboardAction,
  SET_ACTIVE_USER,
} from 'barmer-bonus-shared/src/redux/modules/Dashboard';
import { Dispatch } from '../types/Store';

export default function setUserMiddleware() {
  // @ts-ignore
  return (next: Dispatch): Dispatch => (action: AuthAction | DashboardAction) => {
    switch (action.type) {
      case SET_ACTIVE_USER:
        sessionStorage.setItem('activeUserId', JSON.stringify(action.userId));
        break;
      case LOGOUT:
        sessionStorage.clear();
        break;
      default:
    }
    next(action);
  };
}
