export const ADD_FILE = 'barmer-bonus-web/bonusbookletuploadstate/ADD_FILE' as const;
export const REMOVE_FILE = 'barmer-bonus-web/bonusbookletuploadstate/REMOVE_FILE' as const;
export const CURRENT_NAME = 'barmer-bonus-web/bonusbookletuploadstate/CURRENT_NAME' as const;
export const TOO_LARGE = 'barmer-bonus-web/bonusbookletuploadstate/TOO_LARGE' as const;
export const RESET = 'barmer-bonus-web/bonusbookletuploadstate/RESET' as const;
export const SET_YEAR = 'barmer-bonus-web/bonusbookletuploadstate/SET_YEAR' as const;
export const SET_AVAILABLE_YEARS = 'barmer-bonus-web/bonusbookletuploadstate/SET_YEARS' as const;
export const TYPE_ERROR = 'barmer-bonus-web/bonusbookletuploadstate/TYPE_ERROR' as const;
export const CLEAR_ERROR = 'barmer-bonus-web/bonusbookletuploadstate/CLEAR_ERROR' as const;
export const BONUS_BOOKLET_WIZARD_NEXT = 'barmer-bonus-web/bonusbookletuploadstate/BONUS_BOOKLET_WIZARD_NEXT' as const;
export const BONUS_BOOKLET_WIZARD_PREV = 'barmer-bonus-web/bonusbookletuploadstate/BONUS_BOOKLET_WIZARD_PREV' as const;

type AddFile = { type: typeof ADD_FILE; payload: { file: File } };
type RemoveFile = { type: typeof REMOVE_FILE; payload: { index: number } };
type CurrentName = { type: typeof CURRENT_NAME; payload: string };
type TooLarge = { type: typeof TOO_LARGE; payload: boolean };
type Reset = { type: typeof RESET; payload: {} };
type SetBookletYear = { type: typeof SET_YEAR; payload: number };
type TypeError = { type: typeof TYPE_ERROR; payload: {} };
type ClearError = { type: typeof CLEAR_ERROR; payload: {} };

export type Action =
  | AddFile
  | RemoveFile
  | CurrentName
  | TooLarge
  | Reset
  | TypeError
  | ClearError
  | SetBookletYear;

export type State = {
  files: File[];
  fileTooLarge: boolean;
  wrongFileType: boolean;
  selectedYear: number;
};

const initialState: State = {
  files: [],
  fileTooLarge: false,
  selectedYear: new Date().getFullYear(),
  wrongFileType: false,
};

export default function reducer(state: State = initialState, action: Action): State {
  switch (action.type) {
    case SET_YEAR:
      return {
        ...state,
        selectedYear: action.payload,
      };
    case ADD_FILE:
      return {
        ...state,
        fileTooLarge: false,
        files: [...state.files, action.payload.file],
      };
    case REMOVE_FILE:
      return {
        ...state,
        files: state.files.filter((_, i) => i !== action.payload.index),
      };
    case TOO_LARGE:
      return {
        ...state,
        fileTooLarge: action.payload,
      };
    case TYPE_ERROR:
      return {
        ...state,
        wrongFileType: true,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        wrongFileType: false,
        fileTooLarge: false,
      };
    case RESET:
      return initialState;
    default:
      return state;
  }
}

export function addFile(file: File): AddFile {
  return { type: ADD_FILE, payload: { file } };
}

export function removeFile(index: number): RemoveFile {
  return { type: REMOVE_FILE, payload: { index } };
}

export function tooLarge(flag: boolean): TooLarge {
  return { type: TOO_LARGE, payload: flag };
}

export function reset(): Reset {
  return { type: RESET, payload: {} };
}

export function typeError(): TypeError {
  return { type: TYPE_ERROR, payload: {} };
}

export function clearError(): ClearError {
  return { type: CLEAR_ERROR, payload: {} };
}

export function setBookletYear(y: number): SetBookletYear {
  return { type: SET_YEAR, payload: y };
}
