/* eslint-disable @typescript-eslint/camelcase */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  de: {
    translation: {
      error: {
        pageNotFound: 'Die Seite wurde leider nicht gefunden.',
        loadDashboardError: 'Hier gab es leider ein Problem.',
        redirectBonus: 'Zurück zum Bonusprogramm',
        redirectPlus: 'Zurück zur Plus-Challenge',
        redirectProfile: 'Zurück zu den Einstellungen',
        reloadDashboard: 'Erneut versuchen',
      },
      contact: {
        title: 'Sie haben Fragen?',
        message:
          'Vielen Dank für die Nutzung unseres Online-Services! Bei Fragen stehen wir Ihnen gerne zur Verfügung. Alle Kontaktwege zur BARMER finden Sie hier.',
        link: 'zu barmer.de',
      },
      login: {
        close: 'Schließen',
        headline: 'Anmeldung',
        paragraph: 'Bitte logge dich mit den Zugangsdaten für dein BARMER Benutzerkonto ein.',
        usernamePlaceholder: 'Benutzername',
        passwordPlaceholder: 'Passwort', // NOSONAR
        submitButton: 'Login',
        helpLink: 'Hilfe zum Login',
        passwordForgotten: 'Passwort vergessen?', // NOSONAR
        registerLink: 'Neues Benutzerkonto anlegen',
        loginServerError:
          'Leider gab es ein technisches Problem. Bitte versuche es später noch einmal.',
        loginNoSession: 'Ihre Session ist abgelaufen. Bitte loggen Sie sich erneut ein.',
        info: 'Info',
      },
      activityoverview: {
        headline: 'Bonusmaßnahmen einreichen',
        year_select: 'Jahresauswahl',
        year_prompt: 'Für welches Jahr möchtest du eine Maßnahme einreichen?',
        activity_select: 'Maßnahmenauswahl',
        activity_prompt: 'Welche Maßnahme möchtest du einreichen?',
        open_activities: 'Offene Maßnahmen',
        submitted_activities: 'Eingereichte Maßnahmen',
        open_activities_breakpoint_s: 'Offene',
        submitted_activities_breakpoint_s: 'Eingereichte',
        text: '',
        headline_suffix_adult: '- Erwachsene',
        headline_suffix_teen: '- Jugendliche',
        headline_suffix_kids: '- Kinder',
        points: 'Punkte',
        directReward: 'Einzelbonus {{valueInEUR}} Euro',
        accepted: 'Bestätigt',
        'in-review': 'Eingereicht',
        rejected: 'Aktion erforderlich',
        locked: 'In Prüfung',
        open: ' ',
        prominent_bonus_booklet_headline: 'Hast du schon Bonus Maßnahmen gesammelt?',
        prominent_bonus_booklet_submit: 'Heft einreichen',
        prominent_bonus_booklet_dismiss: 'Jetzt nicht',
        bonus_booklet_headline:
          'Hast du schon Bonusmaßnahmen gesammelt? Hier kannst du sie ganz einfach einreichen.',
        bonus_booklet_submit: 'Bonusheft einreichen',
        listEmptyTitle: 'Noch nicht verfügbar',
        listEmpty: 'Die Bonusmaßnahmen für dieses Jahr stehen in Kürze zur Verfügung.',
      },
      activitydetails: {
        points: 'Punkte',
        subtitleDirectReward: ' oder Einzelbonus {{maxEUR}} Euro',
        heading_how: 'So weist du die Maßnahme nach',
        heading_what: 'So sammelst du Punkte',
        heading_more: 'Mehr zum Thema',
        heading_receipt: 'Dein Nachweis',
        state_in_review: 'Eingereicht',
        state_text_in_review:
          'Vielen Dank für deine Einreichung. Die Gutschrift deiner Bonuspunkte oder deines Einzelbonus wird bis zur Prüfung für dich vorgemerkt.',
        state_locked: 'In Prüfung',
        state_text_locked:
          'Deine Einreichung wird bereits von unserem Servicecenter geprüft. Eine Bearbeitung ist deshalb nicht mehr möglich. ',
        state_pending: 'Eingereicht',
        state_text_pending:
          'Vielen Dank für deine Einreichung. Die Bonuspunkte sind bis zur Prüfung deinem Punktestand vorgemerkt.',
        state_accepted: 'Bestätigt',
        state_text_accepted:
          'Deine Einreichung wurde als gültiger Nachweis erkannt. Dein Bonus wurde dir gutgeschrieben.',
        state_rejected: 'Abgelehnt',
        state_text_rejected:
          'Bitte lade einen gültigen Nachweis hoch. Bei Fragen, kannst du dich gerne an unser Servicecenter wenden.',
        uploaded: 'Zuletzt aktualisiert am',
        receipt_text:
          'Bitte lade einen gültigen Nachweis hoch. Wenn du Fragen zu den Nachweisen hast, kontaktiere gern unser Service Center.',
        upload: 'Jetzt einreichen',
        uploadNew: 'Neuen Nachweis einreichen',
        contactServiceCenter: 'Service Center kontaktieren',
        back: '< Zurück zur Übersicht',
        deleteActivity: 'Einreichung löschen',
        previewActivity: 'Einreichung ansehen',
        edit: 'Bearbeiten',
        delete: 'Löschen',
      },
      activityupload: {
        title: 'Übersicht deiner Maßnahme',
        uploadDoc: 'Lade hier eine oder mehrere Seiten deines Nachweises hoch.',
        receipt: 'Nachweis',
        addReceipt: 'Nachweis hinzufügen',
        uploadAnother: 'Weitere Datei hinzufügen',
        submit: 'Einreichen',
        save: 'Speichern',
        retry: 'Erneut versuchen',
        back: 'Zurück',
        next: 'Weiter',
        remove: 'Löschen',
        loading: 'Laden...',
        tooLarge: 'Hoppla, die hochgeladene Datei darf 3,3 MB nicht überschreiten',
        wrongType: 'Die ausgewählte Datei muss ein PDF oder Bild (PNG/JPEG) sein.',
        uploadErrorTitle: 'Uh oh!',
        uploadErrorMessage:
          'Hoppla! Da ist wohl etwas schief gelaufen. Die Seite konnte leider nicht hochgeladen werden.',
        uploadError406:
          'Die Gesamtzahl deiner Dateien oder Seiten in PDF-Dokumenten darf 5 nicht überschreiten.',
        uploadError415: 'Eine oder mehrere Dateien werden nicht unterstützt oder sind fehlerhaft.',
        uploadConfirmationTitle: 'Eingereicht',
        uploadConfirmationMessage:
          'Deine Maßnahme wird jetzt von uns geprüft. Schau in den nächsten Tagen nochmal vorbei und prüfe den Status in der Übersicht.',
        editErrorTitle: 'Die Maßnahme konnte nicht geändert werden',
        editErrorMessage:
          'Deine Maßnahme wird gerade von unserem Servicecenter geprüft. Eine Bearbeitung ist deshalb nicht mehr möglich.',
        editConfirmationTitle: 'Maßnahme wurde geändert',
        editConfirmationMessage:
          'Deine Maßnahme wurde geändert und ist jetzt in Prüfung. Schau in den nächsten Tagen nochmal vorbei und prüfe den Status auf deiner Übersicht.',
        bonusActivity: 'Bonusmaßnahme',
        bonusActivityYear: 'Jahr',
        bonusActivityFile: 'Nachweise',
        chooseYearHeadline: 'Für welches Jahr möchtest du die Maßnahme einreichen?',
        chooseYearInfo:
          'Es werden nur die Jahre aufgelistet, in denen diese Maßnahme verfügbar ist und noch eingereicht werden kann.',
        vaccinationDateLabel: 'Datum der Impfung',
        vaccinationNameLabel: 'Art der Impfung (wie im Impfbuch)',
        vaccinationNamePlaceholder: 'Bezeichnung wie im Impfbuch',
        verificationDatePlaceholder: 'TT.MM.YYYY',
        rewardType: {
          title: 'Bonus-Art',
          editHeadline: 'Bitte wähle eine Bonus-Art',
          typeBonusPoints: 'Punkte sammeln',
          typeDirectReward: 'Einzelbonus',
          bonusPointsDescription:
            'Jetzt {{points}} Punkte für eine hochwertige Prämie aus dem Prämienshop sammeln.',
          directRewardDescription:
            'Auf eine Bonuspunktegutschrift wird verzichtet und die direkte Auszahlung des Einzelbonus in Höhe von {{valueInEUR}} Euro beantragt.',
          ibanLabel: 'Bitte gib die IBAN einer inländischen Bank an.',
          ibanPlaceholder: 'IBAN',
        },
      },
      deleteactivity: {
        deleteActivityHeadline: 'Maßnahme wirklich löschen?',
        deleteActivityCopy: 'Bitte beachte, dass du deine Maßnahme hiermit unwiderruflich löschst.',
        confirm: 'Ja, löschen',
        cancel: 'Abbrechen',
        deletionSuccessHeadline: 'Maßnahme ist gelöscht',
        deletionSuccessCopy:
          'Deine Maßnahme wurde erfolgreich gelöscht. Du kannst sie jederzeit erneut einreichen.',
        backToTimeline: 'Zur Übersicht',
        deletionErrorTitle: 'Die Maßnahme konnte nicht gelöscht werden',
        deletionErrorMessage: 'Es gab einen Fehler. Bitte versuche es später erneut.',
        deletionError409:
          'Deine Maßnahme wird gerade von unserem Servicecenter geprüft. Eine Löschung ist deshalb nicht mehr möglich.',
      },
      errorScreen: {
        redirectToTimeline: 'Zur Übersicht',
        retry: 'Erneut versuchen',
      },
      confirmationScreen: {
        BONUS_BOOKLET: {
          title: 'Eingereicht',
          message:
            'Dein Bonusheft ist jetzt eingereicht. Schau in den nächsten Tagen nochmal vorbei und prüfe den Status in der Übersicht.',
        },
        PHYSICAL_REWARD: {
          title: 'Prämie ist bestellt',
          titleAfterRemoval: 'Prämie ist auf dem Weg',
          message:
            'Deine Prämie wurde erfolgreich bestellt und sollte in einigen Tagen bei dir ankommen.',
          messageAfterRemoval:
            'Deine Prämie ist angefordert und wird dir bald gutgeschrieben oder übermittelt.',
        },
        DONATION_REWARD: {
          title: 'Punkte wurden gespendet',
          message: 'Deine Punkte wurden erfolgreich gespendet.',
        },
        GIFT_REWARD: {
          title: 'Punkte wurden verschenkt',
          message: 'Deine Punkte wurden erfolgreich verschenkt.',
        },
        SUBVENTION_REWARD: {
          title: 'Zuschussprämie ist angefordert',
          message:
            'Deine Zuschussprämie ist angefordert und sollte dir zeitnah gutgeschrieben werden.',
        },
        redirectToTimeline: 'Zur Übersicht',
        redirectToOverview: 'Weitere Maßnahme einreichen',
      },
      bonusbookletdetails: {
        title: 'Bonusheft',
        upload: 'Bonusheft hochladen',
        back: '< Zurück zur Übersicht',
      },
      bonusbookletupload: {
        uploadTitle: 'Dein Bonusheft',
        yearSelectionTitle: 'Mein Bonusheft ist für das Jahr:',
        activitySelectionTitle: 'Maßnahmen im Bonusheft:',
        activitySelectionPrompt: 'Wähle mehrere Maßnahmen gleichzeitig aus',
        externalLinksTitle: 'Mehr erfahren',
        uploadButtonTitle: 'Dein Bonusheft Nachweis',
        yearNotAvailableSingle:
          'Für das Jahr {{year}} kannst du kein Bonusheft mehr einreichen. Du hast bereits alle Aktivitäten für dieses Jahr eingereicht.',
        yearNotAvailableMultiple:
          'Für die Jahre {{firstYears}} und {{lastYear}} kannst du kein Bonusheft mehr einreichen. Du hast bereits alle Aktivitäten für diese Jahre eingereicht.',
        labelNext: 'Weiter',
        labelBack: 'Zurück',
        submitActivity: 'Maßnahme einreichen',
        submitActivities: 'Maßnahmen einreichen',
        submit: 'Einreichen',
        closingText:
          'Aktivitäten, die du nicht in der Liste findest, hast du für dieses Jahr bereits erfolgreich eingereicht.',
        uploadDoc: 'Bitte achte darauf, dass dein Name auf dem Bonusheft eingetragen ist.',
        addFile: 'Nachweis einreichen',
        addAnother: 'Weitere Nachweise hinzufügen',
        tooLarge: 'Hoppla, die hochgeladene Datei darf 3,3 MB nicht überschreiten',
        wrongType: 'Die ausgewählte Datei muss ein PDF oder Bild (PNG/JPEG) sein.',
        uploadError:
          'Hoppla! Da ist wohl etwas schief gelaufen. Die Seite konnte leider nicht hochgeladen werden.',
        uploadError406:
          'Die Gesamtzahl deiner Dateien oder Seiten in PDF-Dokumenten darf 5 nicht überschreiten.',
        uploadError415: 'Eine oder mehrere Dateien werden nicht unterstützt oder sind fehlerhaft.',
        overviewTitle: 'Übersicht deines Bonusheftes',
        bonusActivity: 'Bonusmaßnahme',
        bonusActivity_plural: 'Bonusmaßnahmen',
        year: 'Jahr',
        attachment: 'Nachweis',
        edit: 'Bearbeiten',
      },
      tos: {
        headlineBonus: 'Willkommen in der BARMER Bonus-App!',
        textBonus:
          'Bestätige bitte die <0>Teilnahmebedingungen und Datenschutzbestimmungen</0>, um die Bonus-App nutzen zu können.',
        acceptLabelBonus: 'Ich stimme den Teilnahmebedingungen und Datenschutzbestimmungen zu.',
        changeHeadlineBonus: 'Wir haben unsere Nutzungsbedingungen aktualisiert',
        changeTextBonus:
          'Hier erfährst du, was sich an unseren <0>Teilnahmebedingungen und Datenschutzbestimmungen</0> zum BARMER Bonusprogramm geändert hat:',
        headlinePlus: 'Willkommen in der Majorel Plus-Challenge',
        textPlus:
          'Bestätige bitte die <0>Teilnahmebedingungen und Datenschutzbestimmungen</0>, um die Plus-Challenge nutzen zu können.',
        acceptLabelPlus: 'Ich stimme den Teilnahmebedingungen und Datenschutzbestimmungen zu.',
        changeHeadlinePlus:
          'Die Nutzungsbedingungen der Majorel Plus-Challenge haben sich aktualisiert',
        changeTextPlus:
          'Hier erfährst du, was sich an den <0>Teilnahmebedingungen und Datenschutzbestimmungen</0> geändert hat:',
        accept: 'Weiter',
        decline: 'Abbrechen',
        serverError: 'Leider gab es ein technisches Problem, bitte versuche es später noch einmal.',
        majorelInfo:
          'Bitte beachte, dass die Plus-Challenge eigenverantwortlich von unserem Partner majorel Wilhelmshaven GmbH betrieben wird und ihren Teilnahme- und Datenschutzbestimmungen unterliegt. Sobald du deine Teilnahme an der Plus-Challenge bestätigst, verlässt du den Verantwortungsbereich der BARMER und nutzt einen Service der majorel Wilhelmshaven GmbH.',
      },
      navi: {
        bonusprogramm: 'Bonusprogramm',
        pluschallenge: 'Plus-Challenge',
        dashboard: 'Dashboard',
        settings: 'Einstellungen',
        login: 'Anmeldung',
        logout: 'Ausloggen',
      },
      bonusDashboard: {
        headline: 'Bonuspunkte',
        allocated: 'vorgemerkt',
        submitActivity: 'Maßnahme einreichen',
        emptyTimeline:
          'Sammle mindestens 500 Punkte im Jahr und erhalte tolle Prämien. Starte jetzt mit deiner ersten Maßnahme!',
        timeline: 'Übersicht',
        deals: 'Prämien',
        shopLink: 'Prämienshop',
        shopLinkAfterRemoval: 'Geldprämie',
        dropdownAddUsers: 'Kind hinzufügen',
        dropdownClose: 'Schließen',
        addUsersLabel: 'Kind hinzufügen',
        addUserSuccessHeadline: 'Familienversichertes Kind hinzugefügt',
        addUserSuccessInfo:
          'Das familienversicherte Kind wurde für das Bonusprogramm registriert. Auf dem Bonus-Dashboard kannst du jetzt das Kind auswählen, Punkte einsehen und Maßnahmen einreichen.',
        addUserServerErrorGeneric:
          'Hier gab es scheinbar ein technisches Problem. Bitte lass uns kurz durchatmen und versuch es später noch einmal.',
        addUserServerErrorUserNotFound:
          'Wir konnten kein Kind unter 15 Jahren finden, das bei dir familienversichert ist. Bitte schau, ob du die richtige Versichertennummer verwendet hast und versuche es noch einmal. Die Versichertennummer findest du auf der Gesundheitskarte deines Kindes und beginnt mit einem Buchstaben.',
        infoLabel: 'Informationen',
        filterTitle: 'Deine Aktivitäten',
        filterLabel: 'Filter',
        filterNoResults:
          'Für die ausgewählten Filter liegen keine eingereichten Maßnahmen oder bestellte Prämien vor.',
      },
      bonusShop: {
        selectRewardButton: 'Prämie auswählen',
        pointCostDetail: '{{points}} Punkte',
        pointCostOverview: '{{points}} Punkte',
        multiplePointVersions: '500 - 1500',
        overviewFilterLabel: 'Nur für meinen Prämienanspruch',
        popupTitle: 'Deine Auswahl',
        popupInfo: 'Bitte überprüfe deine Bestellung.',
        popupRewardLabel: 'Prämie',
        popupCostLabel: 'Wert',
        popupCost: '{{points}} Punkte',
        popupAddressLabel: 'Versand an deine Adresse',
        popupIbanLabel: 'Überweisung an das Konto',
        overviewHeadline: 'Bonusprämien',
        orderNow: 'Verbindlich bestellen',
        back: '< Zurück zum Bonusshop',
        donateLink: 'Punkte spenden',
        giftPointsLink: 'Punkte verschenken',
        subventionLink: 'Gesundheitszuschuss',
        notInGermanyWarning:
          'Leider können Prämien nur nach Deutschland versandt werden. Bitte wende dich für eine Zustellung an eine andere Adresse an unser Servicecenter.',
        rewardNotAvailableToUser: 'Leider kannst du im Moment keine Prämie einlösen.',
        onlySubventionAvailable:
          'Leider kannst du dieses Jahr nur noch eine Zuschussprämie beanspruchen',
        rewardNotAvailableToUserLink: 'Wann habe ich Anspruch auf eine Prämie?',
        popupPointsRecipient: 'Punkte übertragen an',
        popupBackButton: 'Zurück',
        notInGermany:
          'Bitte beachte, dass Sachprämien nur nach Deutschland geliefert werden können.',
        notInGermanyDetail:
          'Leider können Prämien nicht an deine Adresse außerhalb Deutschlands versandt werden. Bitte wende dich für eine Bestellung an unser Servicecenter.',
        serviceCenterFAQLink: 'Wie erreiche ich das Servicecenter?',
        claimsWarning:
          'Bitte beachte, dass du mit dieser Bestellung deinen Anspruch auf eine Prämie pro Kalenderjahr im Wert von bis zu 1500 Punkten einlöst.\n\nGut zu wissen: Wenn du eine Geldprämie wählst, Punkte spendest oder schenkst, kannst du einmal im Jahr auch eine Zuschussprämie erhalten, wenn du noch nicht mehr als 1.000 Punkte eingelöst hast.',
        claimsWarning1500:
          'Bitte denke daran, dass du einen Prämienanspruch von maximal 1.500 Punkten hast. Die Bestellung einer weiteren Prämie für dieses Kalenderjahr ist dann nicht mehr möglich.',
        claimsWarningSubvention:
          'Bitte beachte, dass du mit dieser Bestellung deinen Prämienanspruch verbrauchst.\n\nGut zu wissen: Wenn du die Zuschussprämie wählst, kannst du einmal im Jahr auch noch eine Geldprämie im Wert von 150, 500 oder 1.000 Punkte erhalten bzw. entsprechend viele Punkte spenden oder schenken.',
      },
      bonusShopInfo: {
        headline: 'Deine Prämienoptionen',
        description:
          'Deinen kalenderjährlichen Prämienhöchstanspruch von 1.500 Bonuspunkten kannst du folgendermaßen einlösen:',
        option1: {
          description:
            'Wähle aus zwischen <0>einer</0> Sachprämie, Geldprämie, Punkteschenkung oder Punktespende im Wert von',
          descriptionAfterRemoval:
            'Wähle aus zwischen <0>einer</0> Geldprämie, Punkteschenkung oder Punktespende im Wert von',
          points: '1500 Bonuspunkten',
        },
        option2: {
          description:
            'Wähle aus zwischen <0>einer</0> Sachprämie, Geldprämie, Punkteschenkung oder Punktespende im Wert von',
          descriptionAfterRemoval:
            'Wähle aus zwischen <0>einer</0> Geldprämie, Punkteschenkung oder Punktespende im Wert von',
          points: '150, 500 oder 1000 Bonuspunkten',
          inCombination: 'in Kombination mit <0>einer</0>',
          subventionReward: 'Zuschussprämie',
          brackets: '(für 500 Bonuspunkte und auch einzeln wählbar)',
        },
        ok: 'Ok, verstanden!',
        details: 'Detaillierte Regeln anzeigen',
      },
      filtersPopup: {
        filterByKind: 'Filter nach Art',
        filterByTime: 'Filter nach Zeit',
        activities: 'Maßnahmen',
        activitiesDescription:
          'Mit der Auswahl werden dir Maßnahmen angezeigt, die du in dem ausgewählten Jahr <0>eingereicht</0> hast.',
        rewards: 'Prämien',
        rewardsDescription:
          'Mit der Auswahl werden dir Prämien angezeigt, die du in dem ausgewählten Jahr <0>bestellt</0> hast.',
        submit: 'Filter anwenden',
        reset: 'Filter zurücksetzen',
      },
      bonusrewardUpload: {
        uploadAnother: 'Weitere Seite hinzufügen',
      },
      monetaryReward: {
        headline: 'An welches Konto sollen wir den Betrag überweisen?',
        remark: 'Bitte gib ein Konto einer deutschen Bank an.',
        namePlaceholder: 'Max Mustermann',
        ibanPlaceholder: 'IBAN',
        errorIban:
          'Bitte gib ein deutsches Konto an. Solltest du kein deutsches Konto haben, wende dich bitte an unser Servicecenter: 0800 33 33 200  (montags bis freitags von 8 bis 18 Uhr, deutschlandweit kostenfrei).',
        buttonLabel: 'Weiter',
        timeline: 'Eingereicht',
        deals: 'Prämien',
        backLabel: 'Zurück',
      },
      giftReward: {
        acceptButtonLabel: 'Weiter',
        backLabel: 'Zurück',
      },
      bonusGiftPoints: {
        headline: '500 Bonuspunkte verschenken',
        description:
          'Verschenke Bonuspunkte an Familienmitglieder, Freunde oder Kollegen, die auch am Bonusprogramm teilnehmen. So könnt ihr euch gemeinsam eine besonders hochwertige Prämie sichern.',
        form_headline: 'Wer soll die {{numPoints}} Punkte empfangen?',
        transfer_error:
          'Leider kannst du im Moment keine Punkte verschenken. Die Bedingungen zum Verschenken der Punkte kannst du in den FAQ nachlesen.',
        form_error:
          'Es konnte kein Versicherter zu den angegebenen Daten gefunden werden. Bitte versuche es erneut.',
        validation_error:
          'Bitte gib eine gültige Krankenversichertennummer ein. Die Krankenversichertennummer steht vorne auf der Gesundheitskarte und beginnt mit einem Buchstaben.',
        placeholder_firstName: 'Vorname',
        placeholder_lastName: 'Nachname',
        placeholder_Kvnr: 'Krankenversichertennummer',
        submit: 'Punkte verschenken',
        explain1:
          'Pro Jahr kannst du 500 Punkte an andere BARMER-Versicherte verschenken, die auch am Bonusprogramm teilnehmen. Voraussetzung ist, dass du in diesem Jahr schon mindestens eine Maßnahme eingereicht und deinen Prämienanspruch für dieses Kalenderjahr noch nicht geltend gemacht hast.',
        explain2: 'Du kannst auch mehrmals  Punkte verschenken.',
        explain3: 'Das Schenken der Punkte kannst du nicht rückgängig machen.',
        areyoushure: 'Möchtest du deine Punkte wirklich übertragen?',
        cancel: 'Abbrechen',
        back: '< Dashboard',
        kvnrExplanation:
          'Die Krankenversichertennummer steht vorne auf der Gesundheitskarte und beginnt mit einem Buchstaben.',
        serverError:
          'Wir konnten den Versicherten nicht in unserem System finden. Bitte überprüfe die Eingaben.',
      },
      plusDashboard: {
        headline: 'Plus-Challenge',
        activities: 'Aktivitäten',
        timeline: 'Timeline',
        deals: 'Deals',
        addButton: 'Was hast Du heute gemacht?',
        thisWeek: 'Diese Woche',
        lastWeek: 'Letzte Woche',
        weeksAgo: ' Wochen',
        today: 'Heute',
        yesterday: 'Gestern',
        emptyTimeline:
          'Starte jetzt mit deiner ersten Aktivität und sichere dir ab Level 1 attraktive Partnerdeals',
        timeLineError:
          'Deine Timeline konnte nicht geladen werden, versuche es später noch einmal.',
        switchUserHeadline: 'Nur für dich',
        switchUserInfo:
          'Die Plus-Challenge kannst du nur als Hauptversicherter nutzen. Unternehmungen mit deinen Kindern kannst du gerne als Familien-Aktivität sammeln.',
        switchUserButton: 'Zum Hauptaccount wechseln',
        registrationNotAllowed:
          'Leider kannst du die Plus Challenge erst etwa 24 Stunden nach deiner Abmeldung erneut starten. Bitte versuch’s später nochmal.',
        onhold: {
          headeline: 'Plus-Challenge wurde beendet',
          text:
            'Die Plus-Challenge wurde zum 31.12.2020 beendet, doch wir überlegen uns spannende Neuerungen für dich. Deine Plus-Challenge Daten werden sicher gelöscht.',
        },
      },
      plusShop: {
        yourDeals: 'Deals für dein Level',
        allDeals: 'Alle Deals anzeigen',
        errorMessage: "Hoppla! Hier gab's wohl ein Problem. Bitte lade den Screen noch einmal.",
        tryAgain: 'Nochmal versuchen',
      },
      onboarding: {
        next: "Los geht's",
        step1Title: 'Vorsorge & gesunder Lebensstil zahlen sich aus.',
        step1:
          'Dir ist deine Gesundheit wichtig und du sorgst regelmäßig vor? Mit der BARMER Bonus-App belohnen wir dich dafür.',
        step2Title: 'Das Bonusprogramm – jetzt auch digital.',
        step2:
          'Mit der Bonus-App kannst du noch leichter mit vielen Vorsorge- und Gesundheitsaktivitäten punkten. Sammle mindestens 500 Punkte im Jahr und such dir eine Prämie aus.',
        step3Title: 'Noch mehr punkten mit der Plus-Challenge.',
        step3:
          'Du machst Sport, ernährst dich gesund oder meditierst? Mit der Plus-Challenge kannst du deine Aktivitäten sammeln und erhältst tolle Partner-Deals.',
      },
      plusOnboarding: {
        next: 'Plus-Challenge starten',
        step1:
          'Du willst Motivation für mehr Bewegung, gesunde Ernährung und Entspannung im Alltag? Mach mit bei der Plus-Challenge und lass dich für gesunde Aktivitäten mit tollen Partner-Deals belohnen.',
        step2:
          'Sammle pro Woche bis zu 30 Aktivitäten aus den Bereichen Bewegung, Ernährung und Entspannung.',
        step3:
          'Erreiche nach und nach neue Level. Versuche dabei regelmäßig aktiv zu sein, da dir Aktivitäten abgezogen werden, wenn du mehr als zwei Wochen lang inaktiv bist.',
        step4:
          'Mit jedem Level schaltest du neue Partner-Deals frei. Belohn dich für deinen Einsatz mit vielen tollen Angeboten.',
      },
      plusTos: {
        headline: 'Teilnahmebedingungen & Datenschutz Plus-Challenge',
        part1:
          'Bitte lies Dir die Nutzungsbedingungen und Datenschutzrichtlinien gut durch und akzeptiere diese.',
        part2: 'PLACEHOLDER',
        linkMembership: 'Teilnahmebedingungen',
        linkDataProtection: 'Datenschutzbestimmungen',
        accept: 'Akzeptieren',
      },
      plusActivities: {
        headline: 'Plusaktivitäten',
        manual: 'Manuell hinzufügen',
        automatic: 'Automatisch tracken',
        back: '< Zurück zur Übersicht',
        teaser: 'Nutze die BARMER Bonus-App, um Aktivitäten automatisch zu tracken.',
        noteaser:
          'Deine Trackingeinstellungen kannst du auf deinem Smartphone in Apple Health oder Google Fit verwalten.',
        'noteaser-google':
          'Deine Trackingeinstellungen kannst du auf deinem Smartphone in Google Fit verwalten.',
        'noteaser-apple':
          'Deine Trackingeinstellungen kannst du auf deinem Smartphone in Apple Health verwalten.',
      },
      plusActivityDetails: {
        activityHeadline: 'Deine Aktivität',
        trackerHeadline: 'Tracker-Anbindung',
        addActivity: 'Aktivität hinzufügen',
        trackingApple: 'Apple Health verknüpft',
        trackingGoogle: 'Google Fit verknüpft',
        remarkActivityOpen:
          'Du hast heute noch keine Aktivität gesammelt. Was hast du heute gemacht?',
        remarkActivityDone:
          'Du hast heute eine Aktivität gespeichert. Damit ist dein Tageslimit erreicht.',
        remarkActivityUntracked:
          'Für diese Aktivität ist eine Verbindung zu Apple Health oder Google Fit erforderlich.',
        'remarkActivityUntracked-google':
          'Für diese Aktivität ist eine Verbindung zu Google Fit erforderlich.',
        'remarkActivityUntracked-apple':
          'Für diese Aktivität ist eine Verbindung zu Apple Health erforderlich.',
        remarkActivityTracked:
          'Heute wurde noch keine Aktivität gespeichert. Bitte beachte, dass Aktivitäten immer gut geschrieben werden, wenn du die BARMER Bonus App öffnest.',
        remarkActivitySubmitted:
          'Du hast dein Ziel heute erreicht und die Aktivität wurde in der Timeline gespeichert.',
        teaserText:
          'Lade dir die Bonus-App aus dem Google Playstore oder Apple App-Store auf dein Smartphone herunter, um Aktivitäten automatisch zu tracken und direkt hochzuladen.',
        connectedGoogleFit: 'Google Fit verknüpft',
        connectedAppleHealth: 'Apple Health verknüpft',
        heading_how: 'So weist du die Aktivität nach',
        heading_what: 'Diese Aktivität zählt',
        back: '< Zurück zur Übersicht',
        AdditionalInfoHeadline: 'Lerne mehr Angebote der BARMER kennen',
      },
      plusActivityUpload: {
        headline: 'Welche Aktivität hast du heute gemacht?',
        addPhoto: 'Foto hinzufügen',
        placeholder_Achtsamkeit: '',
        placeholder_Schritte: '',
        placeholder_Workout: '',
        placeholder_Familie: 'z. B. gemeinsame Radtour mit der Familie, etc.',
        placeholder_Soziales: 'z. B. Blutspende, Erste-Hilfe-Kurs, etc.',
        placeholder_Entspannung: 'z. B. Meditation, Massage, etc.',
        placeholder_Ernährung: 'z. B. grüner Salat, Ernährungsseminar, etc.',
        placeholder_Bewegung: 'z. B. Crossfit Workout, Volleyball, etc.',
        submit: 'Aktivität speichern',
        retry: 'Erneut versuchen',
        tooLarge: 'Hoppla, die hochgeladene Datei darf 3,3 MB nicht überschreiten',
        wrongType: 'Die ausgewählte Datei muss ein PDF oder Bild (PNG/JPEG) sein.',
        uploadError:
          'Oh oh! Da ist wohl etwas schief gelaufen. Dein Upload konnte leider nicht durchgeführt werden.',
        uploadError415: 'Eine oder mehrere Dateien werden nicht unterstützt oder sind fehlerhaft.',
        alreadyUploaded: 'Diese Aktivität wurde bereits eingereicht.',
        previewClose: 'Vorschau schließen',
      },
      appTeaser: {
        text:
          'Mit der BARMER Bonus-App kannst du deine Nachweise ganz einfach mit dem Smartphone hochladen, Bonuspunkte sammeln und Prämien bestellen – wann und wo du willst.',
        close: 'Jetzt nicht',
      },
      profile: {
        header: 'Einstellungen',
        general: 'Allgemein',
        accountsAndData: 'Konten und Daten',
        tos: 'Nutzungsbedingungen',
        bonus: 'Bonusprogramm',
        bonusFAQ: 'Häufig gestellte Fragen',
        quitBonus: 'Bonusprogramm beenden',
        plus: 'Plus-Challenge',
        plusFAQ: 'Häufig gestellte Fragen',
        quitPlus: 'Plus-Challenge beenden',
        info: 'App-Informationen',
        licence: 'Software Lizenzen',
        other: 'Sonstiges',
        tracking: 'Datenerfassung',
        feedback: 'Feedback geben',
      },
      feedback: {
        header: 'Feedback',
        copyTitle: 'Wir freuen uns über deine Meinung.',
        copy:
          'Bei Fragen zum Bonusprogramm oder deinem Nutzerkonto wende dich direkt an unser <0>Service Center</0>.',
        contact: 'E-Mail bei Rückfragen',
        comment: 'Dein Feedback',
        contactRequested:
          'Ich stimme zu, dass meine Kontaktdaten aus der Bonus-App für Rückfragen gemäß den Datenschutzbestimmungen mitgeschickt werden.',
        submit: 'Absenden',
        submittedTitle: 'Vielen Dank!',
        submittedCopy:
          'Wir arbeiten kontinuierlich daran, die Bonus-App zu verbessern. Dein Feedback hilft uns dabei.',
      },
      tracking: {
        header: 'Datenerfassung',
        copyTitle: 'Dein Nutzererlebnis liegt uns am Herzen',
        copy:
          'Wir möchten die Bonus-App stetig verbessern. Hierzu wird die Nutzung der App von uns statistisch ausgewertet und bei Störungen Fehlerberichte an uns gesendet. Diese Daten werden vollständig anonymisiert, da uns deine Privatsphäre am Herzen liegt.',
        toggleTracking: 'Nutzungsstatistik senden',
        toggleErrorMonitoring: 'Fehlerbericht senden',
        acceptAnalytics: 'Nutzungsstatistik senden',
        acceptErrorMonitoring: 'Fehlerbericht senden',
        accept: 'Weiter',
      },
      personal: {
        headline: 'Konten und Daten',
        backlink: '< Profil',
        kvnr: 'Krankenversichertennummer',
        birthday: 'Geburtsdatum',
        address: 'Anschrift',
        email: 'E-Mail',
        bonusStart: 'Teilnahme am Bonusprogramm seit',
        plusStart: 'Teilnahme an der Plus-Challenge seit',
        barmerLink: 'Daten in Meine BARMER ändern',
        addChildrenButton: 'Kind hinzufügen',
        additionalInsuredKvnr: 'Krankenversichertennummer',
        additionalInsuredBirthday: 'Geburtsdatum',
        additionalInsuredMembershipStart: 'Teilnahme am Bonusprogramm seit',
        noAdditionalInsured:
          'Wenn Kinder unter 15 Jahren über dich familienversichert sind, kannst du sie über deinen Account für das Bonusprogramm anmelden und für sie Punkte sammeln. Jugendliche ab 15 Jahren benötigen einen eigenen Account bei Meine BARMER und können damit das Bonusprogramm eigenständig nutzen.',
      },
      addChildrenPopup: {
        headline: 'Familienversichertes Kind hinzufügen',
        introText:
          'Wenn Kinder unter 15 Jahren über dich familienversichert sind, kannst du sie über deinen Account für das Bonusprogramm anmelden und für sie Punkte sammeln. Jugendliche ab 15 Jahren benötigen einen eigenen Account bei Meine BARMER und können damit das Bonusprogramm eigenständig nutzen.',
        explanation:
          'Die Krankenversicherungsnummer findest du vorne auf der Gesundheitskarte deines Kindes. Sie beginnt mit einem Buchstaben.',
        clientValidationError:
          'Bitte gib eine gültige Krankenversichertennummer ein. Die Krankenversicherungsnummer findest du vorne auf der Gesundheitskarte deines Kindes. Sie beginnt mit einem Buchstaben.',
        add: 'Hinzufügen',
        ok: 'Ok',
      },
      quitBonus: {
        headline: 'Teilnahme am BARMER Bonusprogramm beenden',
        paragraph1:
          'Die Teilnahme am Bonusprogramm kannst du schriftlich beenden (BARMER Bonusprogramm, Postfach 1111, 26078 Oldenburg). Bitte nenne in dem Schreiben deinen Namen, dein Geburtsdatum und deine Krankenversichertennummer.',
        paragraph2:
          'Mit Beendigung der Teilnahme werden all deine Daten im Bonusprogramm sofort und unwiderruflich gelöscht. Du kannst dann keine Punkte mehr für Prämien einlösen.',
        paragraph3:
          'Über dich familienversicherte Kinder werden automatisch mit vom Bonusprogramm abgemeldet und auch ihre Daten gelöscht. Solltest du hingegen wünschen, dass deine Kinder weiter am analogen Bonusprogramm teilnehmen können, schreibe das bitte deutlich unter Nennung ihrer Namen und Krankenversichertennummern.',
        paragraph4:
          'Deine BARMER-Versicherung und dein Meine BARMER-Account bleiben von der Abmeldung unberührt und können weiterhin genutzt werden.',
        label: 'Teilnahme beenden',
      },
      quitPlus: {
        headline: 'Teilnahme an der Plus-Challenge beenden',
        paragraph1:
          'All deine Daten in der Plus-Challenge werden sofort und unwiderruflich gelöscht, wenn du deine Teilnahme beendest. Dein Level und alle Ansprüche auf Partnerdeals verfallen.',
        paragraph2:
          'Die Beendigung deiner Teilnahme hat keine Auswirkungen auf das Bonusprogramm. Du kannst weiterhin deine Maßnahmen einreichen und Prämien einlösen. Auch deine BARMER-Versicherung und dein Meine BARMER-Account bleiben von der Abmeldung unberührt und können weiterhin genutzt werden.',
        paragraph3: 'Gib dein Passwort ein, um deine Teilnahme zu beenden.',
        passwordPlaceholder: 'Passwort', // NOSONAR
        passwordError: 'Das Passwort stimmt nicht. Bitte versuche es erneut.', // NOSONAR
        label: 'Teilnahme beenden',
        areyoushure:
          'Möchtest du deine Teilnahme an der Plus-Challenge wirklich beenden und deine Daten zur Plus-Challenge unwiderruflich löschen?',
        cancel: 'Abbrechen',
        confirm: 'Plus-Challenge beendet',
        confirmText:
          'Du hast deine Teilnahme an der Plus-Challenge beendet. Wenn du erneut teilnehmen möchtest, kannst du dich jederzeit über die Bonus-App neu anmelden.',
        ok: 'Ok',
      },
      rules: {
        headline: 'Die Regeln',
        rule1:
          'Nimm an einer passenden Bonusmaßnahme aus der Liste teil und lass dir einen Nachweis ausstellen (z. B. eine Rechnung oder einen Eintrag auf der <0>Stempelkarte</0>).',
        rule2:
          'Fotografiere den Nachweis und lade das Bild für die jeweilige Aktivität in dieser App hoch.',
        rule3: 'Sammle mindestens 150 Punkte und löse sie gegen eine tolle Prämie ein.',
        ok: 'Ok, verstanden',
        details: 'Detaillierte Regeln anzeigen',
      },
      plusrules: {
        title: 'Deine Plus Level',
        headline: 'Plus-Challenge Regeln',
        rule1:
          'Sammele bis zu 30 Aktivitäten pro Woche in deiner Timeline. Du kannst mehrere Aktivitäten pro Tag sammeln, aber jede nur einmal.',
        rule2:
          'Erreiche nach und nach ein neues Level und sichere dir mit jedem neuen Level höherwertigere, attraktive Partnerdeals.',
        rule3: 'Bleib dran! Ab 14 Tagen Inaktivität werden dir täglich Aktivitäten abgezogen:',
        sub1: 'Level 1: minus 1 Aktivität pro Tag',
        sub2: 'Level 2: minus 2 Aktivitäten pro Tag',
        sub3: 'Level 3: minus 3 Aktivitäten pro Tag',
        sub4: 'Level 4: minus 4 Aktivitäten pro Tag',
        sub5: 'Level 5: minus 5 Aktivitäten pro Tag',
        ok: 'Ok, verstanden!',
        details: 'Detailierte Regeln anzeigen',
        lvl1: 'eine Aktivität',
        lvl2: '100 Aktivitäten',
        lvl3: '300 Aktivitäten',
        lvl4: '600 Aktivitäten',
        lvl5: '1000 Aktivitäten',
      },
      footer: {
        copyright: '© BARMER',
        copyrightReserved: 'Alle Rechte vorbehalten',
        legal: 'Nutzungsbedingungen',
        impressum: 'Impressum',
        contact: 'Kontakt',
        barmerLink: 'barmer.de',
      },
      cookieHint: {
        cookieHintText:
          'Diese Website verwendet technisch notwendige Cookies um Zugriff auf gesicherte Bereiche zu ermöglichen. Informationen zum Datenschutz finden Sie unter www.barmer.de/datenschutz',
        cookieHintButton: 'OK',
      },
      selectRewardAmount: {
        multiplePointVersions: '150 - 1500',
        pointsMoney: '{{numPoints}} Punkte für {{numEuros}} €',
        points: '{{points}} Punkte',
        notEnoughtPoints:
          'Für die ausgegrauten Auswahlmöglichkeiten hast du derzeit nicht genügend Punkte.',
        continue: 'Weiter',
        titleGift: 'Wie viele Punkte möchtest du verschenken?',
        titleDonation: 'Wie viele Punkte möchtest du spenden?',
        titleMoney: 'Wie viele Punkte möchtest du einlösen?',
      },
      steps: {
        previous: 'Vorheriges',
        next: 'Nächstes',
      },
      popup: {
        close: 'Schließen',
      },
      userInfo: {
        dismiss: 'Hinweis dauerhaft ausblenden',
        dashboardTitle: 'Wichtiges',
        back: '< Alle Maßnahmen',
      },
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'de',
  });

export default i18n;
