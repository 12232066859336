import * as Sentry from '@sentry/react';
import apiConfigurationMiddleware from 'barmer-bonus-shared/src/redux/middleware/apiConfigurationMiddleware';
import autoLogoutMiddleware from 'barmer-bonus-shared/src/redux/middleware/autoLogoutMiddleware';
import { LOGOUT, NOT_AUTHORIZED } from 'barmer-bonus-shared/src/redux/modules/Auth';
import { sharedReducers } from 'barmer-bonus-shared/src/redux/modules/Reducers';
import {
  PROGRAM_BONUS,
  TERMINATED_REGISTRATION,
} from 'barmer-bonus-shared/src/redux/modules/Registration';
import { applyMiddleware, combineReducers, createStore as create } from 'redux';
import { apiMiddleware } from 'redux-api-middleware';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import config from './config';
import setUserMiddleware from './redux/middleware/setUser';
import skipOnboardingMiddleware from './redux/middleware/skipOnboarding';
import bonusbookletuploadstate from './redux/modules/BonusBookletUploadState';
import bonuspointtransferstate from './redux/modules/BonusPointTransferState';
import bonusshopstate from './redux/modules/BonusShopState';
import plusuploadstate from './redux/modules/PlusUploadState';
import composeResetReducer, { DEFAULT_RESET_TYPE } from './redux/modules/Reset';
import { State } from './redux/types/State';

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

const enhancer = composeWithDevTools(
  applyMiddleware(
    thunk,
    apiConfigurationMiddleware(config),
    apiMiddleware,
    autoLogoutMiddleware,
    setUserMiddleware,
    skipOnboardingMiddleware,
  ),
  sentryReduxEnhancer,
);

const reducers = {
  ...sharedReducers,
  bonusshopstate,
  bonusbookletuploadstate,
  plusuploadstate,
  bonuspointtransferstate,
};

const initialState = Object.keys(reducers).reduce((acc, curr) => {
  // @ts-ignore
  return { ...acc, [curr]: reducers[curr](undefined, { type: DEFAULT_RESET_TYPE }) };
}, {} as State);
// @ts-ignore
initialState.auth.current = '';

export default function createStore() {
  return create(
    persistReducer(
      {
        key: 'root',
        storage,
        stateReconciler: autoMergeLevel2,
      },
      // @ts-ignore
      composeResetReducer(combineReducers(reducers), initialState, [
        DEFAULT_RESET_TYPE,
        LOGOUT,
        NOT_AUTHORIZED,
        (action: any) =>
          action.type === TERMINATED_REGISTRATION && action.meta.program === PROGRAM_BONUS,
      ]),
    ),
    // @ts-ignore
    initialState,
    enhancer,
  );
}
